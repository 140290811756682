<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <KTCard>
          <template v-slot:title>
            Calendar
            <p><small>View Availability of Slots across Halls.</small></p>
          </template>
          <template v-slot:toolbar>
            <div class="col-6 mt-6">
              <b-form-group>
                <b-form-select id="months"
                               v-model="formFields.month"
                               :options="_.map(months,(month,i) => {
                                                       return {
                                                           'value':i + 1,
                                                           'text':month,
                                                       }
                                                       })">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-6 mt-6">
              <b-form-group>
                <b-form-select id="years"
                               v-model="formFields.year"
                               :options="years">
                </b-form-select>
              </b-form-group>
            </div>
          </template>

          <template v-slot:body>
            <div class="my-3" v-for="(hall,index) in halls" :key="index">
              <table
                class="table w-100 table-responsive table-bordered text-center m-0 p-0 calendar-table">
                <thead>
                <tr>
                  <th :colspan="formFields.day + 1">
                    <h2 class="font-size-lg font-weight-bolder">
                      {{ hall.name }}
                    </h2>
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td v-for="(d,index) in formFields.day" :key="index" class="text-center">
                    {{ mergeDateValues(formFields.year, formFields.month, d, "ddd") }}
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(slot,index) in hall.hallSlots"
                    :key="index" v-show="slot.status == 1">
                  <td>{{ slot.short_name }}</td>
                  <template v-for="(d,index) in formFields.day">
                    <td :key="index"
                        :class="compareBookingDate(hall.book_halls, slot.short_name,d,formFields.month,formFields.year)+ ' text-white m-0'"
                        v-b-tooltip.hover.lefttop.html="bookingTooltip(hall.book_halls,d,formFields.month,formFields.year,slot.short_name).content"
                        data-toggle="tooltip" id="tooltip-box">
                      <router-link
                        :to="bookingTooltip(hall.book_halls,d,formFields.month,formFields.year,slot.short_name).link || '#'">
                        {{ index + 1 }}
                      </router-link>
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>

            <table
              class="table w-100 table-responsive table-bordered text-center m-0 p-0 calendar-table">
              <thead>
              <tr>
                <th :colspan="formFields.day + 1">
                  <h2 class="font-size-lg font-weight-bolder">
                    Rooms
                  </h2>
                </th>
              </tr>
              <tr>
                <td></td>
                <td v-for="(d,index) in formFields.day" :key="index" class="text-center">
                  {{ mergeDateValues(formFields.year, formFields.month, d, "DD") }}
                </td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(room,index) in rooms"
                  :key="index">
                <td>{{ room.title }}</td>
                <template v-for="(d,index) in formFields.day">
                  <td :key="index"
                      v-b-tooltip.hover.lefttop.html="roomToolTip(room.bookings, d, formFields.month, formFields.year)"
                      class="m-0">
                    <span>
                      {{ compareRoomDate(room.bookings, d, formFields.month, formFields.year).no_of_room || '-' }}
                    </span>
                  </td>
                </template>
              </tr>
              </tbody>
            </table>

          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import {request} from "@/core/services/Request";
import {getPropertyId} from "@/util/globalFunction";
import moment from "moment-timezone";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";

const DEFAULT_FORM_STATE = {
  month: null,
  year: new Date().getFullYear(),
  day: null,
};

export default {
  mixins: [totalAmountMixin],
  components: {
    KTCard,
  },
  data() {
    return {
      halls: [],
      rooms: [],
      formFields: {...DEFAULT_FORM_STATE},
    };
  },
  mounted() {
    this.getCurrentDMYValue();
  },
  methods: {
    bookingTooltip(data, day, month, year, slot) {
      if (data) {

        let currentDate = this.mergeDateValues(year, month, day);
        let filterData = _.filter(data, (i) => moment(i.from_date).format("YYYY-MM-DD") === currentDate && (slot === i.slot || i.slot === "FD"));

        if (filterData.length > 0) {

          if (filterData.length > 1) {

            filterData = filterData.filter(item => {
              return item?.book_details && item.book_details.status !== 5;
            })
          }

          const result = filterData[0]?.book_details;

          if (result !== undefined) {

            let link = '#'

            if (this.$global.hasPermission('quotationbookingsview')) {
              link = 'bookings/edit/' + result.quotation_id;
            }

            return {
              content: '<div class="tooltip-box" style="padding: 10px 10px">' +
                '<p><b>Guest Name/Number </b><br/>' + result.lead + '</p><br/>' +
                '<p><b>Type Of Event</b><br/> ' + result.event_type + '(' + result.event_guest + ')' + '</p><br/>' +
                '<p><b>Time</b><br/> ' + result.time + '</p><br/>' +
                '<p><b>Booking Amount</b><br/> ' + this.$global.numberToLocationString(result.financial_info.total) + '</p><br/>' +
                '<p><b>Paid Amount</b><br/> ' + this.$global.numberToLocationString(result.financial_info.paid) + '</p><br/>' +
                '<b>Due Amount</b> <br/>' + this.$global.numberToLocationString(result.financial_info.due) + '</div>',
              link: link
            };
          }
        }
      }

      return {content: '', link: ''};
    },
    roomToolTip(booking_info, date, month, year) {

      const {booking} = this.compareRoomDate(booking_info, date, month, year);


      if (booking) {
        console.log({booking})

        let booking_content = ''

        booking.map(b => {

          let currentDate = this.mergeDateValues(year, month, date);

          let room_count = _.sum(b.booking.quotation_rooms.filter(room => {
            return moment(room.check_in_date).format("YYYY-MM-DD") === currentDate && b.room_id === room.room_id
          }).map(room => room.no_of_room))

          // const count = _.sum();
          booking_content += `<p><b>${b.booking.lead.title} </b><br/> ${room_count} </p>`;
        })

        return `<div class="tooltip-box" style="padding: 10px 10px">${booking_content}</div>`;
      } else return '';
    },
    async getHalls() {
      try {
        const response = await request({
          url: `/calendar`,
          method: 'post',
          data: this.formFields
        });

        const {data} = response.data;
        this.halls = data;
      } catch (e) {

      }
    },
    async getRooms() {
      try {
        const response = await request({
          url: `/calendar/rooms`,
          method: 'post',
          data: this.formFields
        });

        const {data} = response.data;
        this.rooms = data;
      } catch (e) {

      }
    },
    getCurrentDMYValue() {
      var d = new Date();
      this.formFields.month = d.getMonth() + 1;
      _.filter(this.years, (i) => {
        if (i.value == d.getFullYear()) {
          this.formFields.year = i.value;
        }
      });

      this.formFields.day = new Date(parseInt(this.formFields.year), this.formFields.month, 0).getDate();
    },
    changeMonth(month) {
      this.formFields.day = new Date(parseInt(this.formFields.year), month, 0).getDate();
      this.getHalls();
      this.getRooms();
    },
    changeYear(year) {
      _.filter(this.years, (i) => {
        if (i.value == year) {
          this.formFields.year = i.value;
        }
      });
      this.changeMonth(this.formFields.month);
    },
    mergeDateValues(year, month, date, format = "YYYY-MM-DD") {
      return moment(year + '-' + month + '-' + date).format(format);
    },
    compareBookingDate(book, slot, date, month, year) {
      let currentDate = this.mergeDateValues(year, month, date);
      const filterData = _.filter(book, (i) => moment(i.from_date).format("YYYY-MM-DD") === currentDate && (i.slot === slot || i.slot === 'FD'));

      let booking_status = '';

      if (filterData.length) {

        if (_.filter(filterData, (i) => i?.book_details?.status === 2).length > 0) {
          booking_status = 'bg-warning'
        } else if(_.filter(filterData, (i) => filterData.length === 1 && i?.book_details?.status === 5).length > 0) {
          booking_status = 'bg-success'
        } else {
          booking_status = 'bg-danger'
        }
      }
      return booking_status;
    },
    compareRoomDate(bookings, date, month, year) {
      let currentDate = this.mergeDateValues(year, month, date);
      const filterData = _.filter(bookings, (i) => {
        return moment(i.date).format("YYYY-MM-DD") === currentDate
      });

      return filterData.length > 0 ? filterData[0] : {};
    },
  },
  computed: {
    years() {
      const allYear = Array.from({length: 6}, (value, index) => 2020 + index);
      return allYear.map((item) => {
        return {
          value: item,
          text: item,
        };
      });
    },
    months() {
      return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    },
  },
  watch: {
    'formFields.year': function (newVal) {
      this.changeYear(newVal);
    },
    'formFields.month': function (newVal) {
      this.changeMonth(newVal);
    }
  }
};
</script>

<style scoped lang="scss">
table a {
  color: black;
}

.table td {
  width: 1%;
}

/*#calendar-day p:last-child {*/
/*    font-size: 8px;*/
/*}*/

.bg-danger a {
  color: #ffffff !important;
}
</style>
